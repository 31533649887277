/*------------------------------------*\
  Components.............Card faq
\*------------------------------------*/

.c-block-block-11 {
  padding: 2.5%;
  border: 1px solid rgba(9, 77, 136, .4);
  margin: 15px;
  background: url('#{$imgPath}/faq-bg.png') center no-repeat;
  background-size: cover;
  border-radius: 2px;

  h5 {
    font-weight: 800;
    margin-bottom: var(--sp-unit);
  }

  p {
    font-size: var(--fz-s);
    font-weight: 600;
    margin-bottom: 0;
  }

  @include media('screen', '>=small') {
    max-width: 320px;
    margin: 0 15px 0 auto;
  }
  
  @include media('screen', '>medium') {
    width: 33%;
    max-width: $in;
    margin: 0 auto 0 var(--sp-double);
  }

  @include media('screen', '>xlarge') {
    width: 355px;
  }
}
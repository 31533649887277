/*------------------------------------*\
  Components.............Teacher home
\*------------------------------------*/

.c-professores-destaque {

  .views-field-field-perf-foto-fid a { @include square(80px); }

  .views-field-field-perf-titulacao-value { color: var(--cl-white); }

  .more-link { text-align: center; }

  .more-link a {
    display: block;
    color: var(--cl-white);
    text-transform: uppercase;
    font-size: var(--fz-s);
    font-weight: 700;
    max-width: 300px;
    padding: var(--sp-twelve) var(--sp-double);
    margin: 0 auto;
    background: $grad-blue;
    border-radius: $rounded-pill;
    
    &:hover { text-decoration: none; }
  }

  div.views-row {
    width: 24%;
    margin-bottom: 0;
  }

  div.view-content {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: var(--sp-double);
  }

  @include media('screen', '<medium') {
    div.views-row {
      width: auto;
      grid-template-columns: 80px 200px;
      margin: 0 var(--sp-half);
    }

    div.view-content {
      width: 100vw;
      overflow-x: auto;
      padding: 8px 0 15px;
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}

.c-block-views-Professores-block_1 {
  position: relative;
  padding: var(--sp-triple) 0;
  margin-bottom: var(--sp-eighty);
  background: $grad-green;

  &::after {
    content: '';
    display: block;
    @include square(100%);
    background: url('#{$imgPath}/teacher-bg.png') center no-repeat;
    position: absolute;
    top: 0;
  }

  &__title {
    color: var(--cl-white);
    text-align: center;
    font-weight: 600;
    font-size: var(--fz-l);
    margin-bottom: var(--sp-double);
  }

  &__desc {
    display: block;
    color: var(--cl-white);
    font-style: normal;
    text-align: center;
    font-weight: 600;
    margin-top: var(--sp-twelve);
  }

  .container {
    position: relative;
    z-index: 5;
  }

  @include media('screen', '>medium') {
    &__title { margin-bottom: var(--sp-triple); }
  }
}

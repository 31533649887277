/*------------------------------------*\
  Components.............Links serviços
\*------------------------------------*/

.c-block-menu-menu-links-servicos {
  color: var(--cl-white);
  background: linear-gradient(90deg, #5c8d4a 0%, #8ec43b 100%);

  li.leaf {
    text-align: center;
    font-size: var(--fz-m);
    list-style: none;
    padding: 0 var(--sp-half);
    margin: 0;
  }
  
  &__link {
    display: block;
    color: var(--cl-white);
    width: 115px;

    &:hover, 
    &:focus { color: var(--cl-white); }
  }
  
  &__title {
    text-transform: uppercase;
    margin-bottom: 0;
  }

  &__sub-title { font-style: normal; }

  &__header {
    font-size: var(--fz-s);
    text-align: center;
    width: 100vw;
    padding: var(--sp-half) var(--sp-twelve);
    margin: 0 -15px;
    background-color: rgba(0, 0, 0, .1);
  }

  .menu {
    overflow-x: auto;
    display: flex;
    align-items: center;
    max-width: 100vw;
    padding: var(--sp-six) 0;
    margin: 0 -15px;
  }

  .container {
    display: flex;
    flex-flow: column;
  }

  @include media('screen', '>xsmall') {
    .menu { justify-content: center; }
  }

  @include media('screen', '>medium') {
    li {
      width: 25%;
      padding: 0 var(--sp-unit);
    }

    &__link { width: $in; }

    &__header {
      width: 33%;
      margin: $in;
    }

    .menu {
      width: 67%;
      padding: var(--sp-six) var(--sp-twelve);
    }

    .container {
      flex-flow: $in;
    }
  }

  @include media('screen', '>large') {
    li {
      font-size: var(--fz-xm);
    }
  }
}
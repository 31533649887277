/*------------------------------------*\
  Components.............Testimonials home
\*------------------------------------*/

.c-block-views-Depoimentos-block_1 {
  margin-bottom: var(--sp-eighty);

  &__btns { margin-top: auto; }

  &__column {
    display: flex;
    flex-flow: column;
    margin-bottom: var(--sp-unit);
  }
  
  @include media('screen', '>medium') {
    &__column {
      width: 33%;
      margin-bottom: 0;
    }
  
    &__body { width: 67%; }
  
    .container { display: flex; }
  }
}


.c-depoimentos-home {
  div.view-content {
    align-items: flex-start;
    flex-wrap: nowrap;
  }

  div.views-row {
    margin-bottom: 0;

    &:first-child { margin-top: var(--sp-double); }
    &:last-child { margin-top: var(--sp-triple); }
  }

  @include media('screen', '<medium') {
    div.view-content {
      overflow-x: auto;
      padding: var(--sp-unit) 0;
      margin: 0 -15px;
    }
  }

  @include media('screen', '>=small') {
    div.views-row { min-width: initial; }
  }
}

/*------------------------------------*\
  Components.............Banner
\*------------------------------------*/

$hBannerSmall: 300px;
$hBannerDeskt: 500px;


// .views-field-field-bann-img-movel-fid, 
// .views-field-field-bann-imagem-fid { display: none; }

// .views-field-field-bann-imagem-fid {
  // @include centerer(both);

  // img {
  //   width: 100vw;
  //   height: auto;
  // }

  // @include media('screen', '>medium') {
  //   img { height: auto; }
  // }

  // @include media('screen', '>xlarge') {
    // img { max-height: 500px; }
  // }
// }

.views-field-field-bann-video-value {
  iframe { display: block; }
  
  @include media('screen', '<small') {
    iframe { width: 100vw; }
  }

  @include media('screen', '<medium') {
    iframe {
      width: 45vw;
      height: 225px;
    }
  }

  @include media('screen', '>=small') {
    z-index: 10;
    @include centerer(right);
    right: 15px;
  }
}

// .view-banner {
//   max-width: 1300px;
//   padding: 0 15px;
//   margin: 0 auto;
// }


.c-block-views-banner-block_1 {
  overflow: hidden;
  position: relative;
  // max-height: $hBannerDeskt;
  background-color: var(--cl-primary);

  img {
    width: 100%;
    height: auto;
  }

  // &::before {
  //   content: '';
  //   width: 100%;
  //   height: 100%;
  //   background: linear-gradient(0deg, #410d66 0%, #8a0091 100%);
  //   opacity: .3;
  //   @include centerer(both);
  //   z-index: 5;
  // }

  // .views-row {
  //   display: flex;
  //   flex-flow: column;
  //   justify-content: center;
  //   height: $hBannerSmall;
  // }

  // .views-field-title, 
  // .views-field-field-bann-video-value {
  //   position: relative;
  //   z-index: 10;
  // }

  // .views-field-title {
  //   color: var(--cl-white);
  //   font-size: var(--fz-l);
  //   min-width: 50%;
  //   margin-bottom: var(--sp-twelve);

  //   a {
  //     color: var(--cl-white);

  //     @include hover {
  //       text-decoration: none;
  //     }
  //   }
  // }

  // @include media('screen', '>=small') {
  //   .views-row {
  //     display: flex;
  //     flex-flow: $in;
  //     justify-content: $in;
  //     align-items: center;
  //     height: $hBannerSmall;
  //   }

  //   .views-field-title { margin-bottom: 0; }
  // }

  // @include media('screen', '>medium') {
    // height: $hBannerDeskt;

    // .views-row { height: $hBannerDeskt; }

    // .views-field-title { font-size: var(--fz-hl); }
  // }
}
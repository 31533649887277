/*------------------------------------*\
  Components.............Courses highlight
\*------------------------------------*/

.c-cursos-destaque {
  
  @include media('screen', '>=xsmall', '<medium') {
    .views-row { @include column(1/2, 0, 2, 2); }
  }

  @include media('screen', '>medium') {
    .views-row {
      @include column(1/4, 0, 0, 1);
      margin-bottom: 0;
    }
  }
}
.c-cursos-destaque-three {
  @include media('screen', '>=small') {
    .views-row {
      @include column(1/3, 0, 0, 1);
      // margin-bottom: 0;
    }
  }
}

.c-block-views-produto-block_1 {
  position: relative;
  margin-bottom: var(--sp-triple);

  &__title {
    margin-bottom: var(--sp-unit);
    padding-right: var(--sp-triple);
  }

  @include media('screen', '>=small') {
    &__title { padding-right: 0; }
  }
}

.quicktabs_tabpage .c-card-cursos .c-card-cursos {
  .views-row {
    font-size: var(--fz-s);
    width: 100%;
    border: 0;
    box-shadow: $n;
  }

  .views-field-name { font-weight: 600; }
}

.quicktabs_tabpage .field-content > .c-card-cursos {
  @include media('screen', '>medium') {
    display: flex;

    > .attachment { width: 23.5%; }

    > .view-content {
      width: 75%;
      margin-left: auto;
    }
  }
}

.c-block-views-Categorias_Carrocel-block_2 {
  .quicktabs_tabs li.active, 
  .quicktabs_main { position: relative; }

  .qt_tab {
    display: inline-block;
    color: var(--cl-primary);
    font-weight: 600;
    padding: var(--sp-quarter) 0 0;
    border-bottom: 2px solid $t;

    &:hover {
      text-decoration: none;
      border-bottom-color: $cc;
    }
  }

  .quicktabs_tabs li.active .qt_tab {
    color: var(--cl-blue);
    border-bottom-color: $cc;
  }

  .quicktabs_tabs, 
  .quicktabs_tabs li { padding: 0; }

  .quicktabs_tabs { margin-bottom: var(--sp-unit); }

  .views-slideshow-controls-top { top: -52px; }
  
  @include media('screen', '<small') {
    .quicktabs_tabs li { display: block; }
    .qt_tab {
      padding: var(--sp-half) var(--sp-twelve);
      margin-bottom: var(--sp-half);
    }
  }
  
  @include media('screen', '>=small') {
    .quicktabs_tabs li.active::before {
      content: '';
      @include triangle(down, 6px, var(--cl-blue));
      @include centerer(bottom);
      bottom: -14px;
    }

    .quicktabs_tabs li:not(:first-child) { margin-left: var(--sp-unit); }
  }

  @include media('screen', '>medium') {
    .quicktabs_tabs {
      width: 75%;
      margin-left: auto;
    }
  }

  @include media('screen', '>large') {
    &__title { margin-bottom: -30px; }
  }
}


// @include media('screen', '<small') {
//   ul.quicktabs_tabs li { display: block; }
// }

.c-block-views-Categorias_Carrocel-block_2 .views_slideshow_singleframe_teaser_section { width: 100%; }
.c-block-views-Categorias_Carrocel-block_2 .views_slideshow_singleframe_teaser_section .views_slideshow_singleframe_slide {
  position: relative $i;
}

@include media('screen', '>=small') {
  .c-block-views-Categorias_Carrocel-block_2 .views_slideshow_singleframe_teaser_section .views_slideshow_singleframe_slide {
    // width: 925px;
    height: 258px;
  }
}

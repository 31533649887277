/*------------------------------------*\
  Components.............Card certificates
\*------------------------------------*/

.c-block-menu-menu-certificados {
  
  li.leaf {
    list-style: none;
    padding: 0;
    margin: 0 0 var(--sp-half);
  }
  .menu { @include list-unstyled; }

  padding: 15px;
  background: rgba($black, .1) url('#{$imgPath}/certificate-bg.png') center no-repeat;
  background-size: cover;

  &__title { margin-bottom: var(--sp-unit); }
  
  &__body::before {
    content: 'Certificado do seu curso, consulte a autenticação válida em todo Brasil.';
    display: block;
    font-size: var(--fz-s);
    font-weight: 600;
    margin-bottom: var(--sp-twelve);
  }


  @include media('screen', '>=small') {
    width: 53%;
    @include border-right-radius(2px);
  }

  @include media('screen', '>medium') {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    width: 62.6%;
    padding: var(--sp-triple) 15px;

    &__title, 
    &__body { width: 100%; }

    &__body { display: flex; }

    &__body::before {
      width: 33%;
      margin-right: var(--sp-double);
    }
  }

  @include media('screen', '>xlarge') {
    &__title, 
    &__body { width: 885px; }
  }
}

@charset "UTF-8";
/*------------------------------------*\
  Settings...............Set tools and configurations for the contents
\*------------------------------------*/
:root {
  --cl-gray-100: #f9f9fa;
  --cl-gray-200: #eeeef1;
  --cl-gray-300: #d7d7db;
  --cl-gray-400: #b2b2b3;
  --cl-gray-500: #737373;
  --cl-gray-600: #4a4a4f;
  --cl-gray-700: #38383d;
  --cl-gray-800: #29292e;
  --cl-gray-900: #0c0c0d;
  --cl-blue: #0f82e6;
  --cl-indigo: #6610f2;
  --cl-purple: #6f42c1;
  --cl-pink: #e83e8c;
  --cl-red: #dc3545;
  --cl-orange: #fd7e14;
  --cl-yellow: #ffc107;
  --cl-green: #28a745;
  --cl-teal: #20c997;
  --cl-cyan: #17a2b8;
  --cl-white: #fff;
  --cl-gray: #4a4a4f;
  --cl-gray-dark: #29292e;
  --cl-primary: #094d88;
  --cl-secondary: #6a8c28;
  --cl-success: #13bd00;
  --cl-info: #0a85ff;
  --cl-warning: #d66f00;
  --cl-danger: #d60020;
  --cl-light: #f9f9fa;
  --cl-dark: #29292e;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

:root, body {
  --fz-uul:    4.5rem;
  --fz-ul:     4rem;
  --fz-hhhl:   3rem;
  --fz-hhl:    2.75rem;
  --fz-hl:     2.25rem;
  --fz-xxl:    2rem;
  --fz-xl:     1.75rem;
  --fz-l:      1.5rem;
  --fz-xxm:    1.25rem;
  --fz-xm:     1.125rem;
  --fz-m:      1rem;
  --fz-s:      0.875rem;
  --fz-xs:     0.705rem;
  --fz-xxs:    0.625rem;
  --sp-quarter:   0.25rem;
  --sp-six:       0.375rem;
  --sp-half:      0.5rem;
  --sp-twelve:    0.75rem;
  --sp-unit:      1rem;
  --sp-unit-half: 1.5rem;
  --sp-double:    2rem;
  --sp-fourty:    2.5rem;
  --sp-triple:    3rem;
  --sp-quad:      4rem;
  --sp-eighty:    5rem;
}

/*------------------------------------*\
  Layouts................Macro arrangement of a web page, including any grid systems
\*------------------------------------*/
/*------------------------------------*\
  Layouts................Home
\*------------------------------------*/
/*------------------------------------*\
  Components.............Banner
\*------------------------------------*/
.views-field-field-bann-video-value iframe {
  display: block;
}

@media screen and (max-width: 767px) {
  .views-field-field-bann-video-value iframe {
    width: 100vw;
  }
}

@media screen and (max-width: 991px) {
  .views-field-field-bann-video-value iframe {
    width: 45vw;
    height: 225px;
  }
}

@media screen and (min-width: 768px) {
  .views-field-field-bann-video-value {
    z-index: 10;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    right: 15px;
  }
}

.c-block-views-banner-block_1 {
  overflow: hidden;
  position: relative;
  background-color: var(--cl-primary);
}

.c-block-views-banner-block_1 img {
  width: 100%;
  height: auto;
}

/*------------------------------------*\
  Components.............Links serviços
\*------------------------------------*/
.c-block-menu-menu-links-servicos {
  color: var(--cl-white);
  background: linear-gradient(90deg, #5c8d4a 0%, #8ec43b 100%);
}

.c-block-menu-menu-links-servicos li.leaf {
  text-align: center;
  font-size: var(--fz-m);
  list-style: none;
  padding: 0 var(--sp-half);
  margin: 0;
}

.c-block-menu-menu-links-servicos__link {
  display: block;
  color: var(--cl-white);
  width: 115px;
}

.c-block-menu-menu-links-servicos__link:hover, .c-block-menu-menu-links-servicos__link:focus {
  color: var(--cl-white);
}

.c-block-menu-menu-links-servicos__title {
  text-transform: uppercase;
  margin-bottom: 0;
}

.c-block-menu-menu-links-servicos__sub-title {
  font-style: normal;
}

.c-block-menu-menu-links-servicos__header {
  font-size: var(--fz-s);
  text-align: center;
  width: 100vw;
  padding: var(--sp-half) var(--sp-twelve);
  margin: 0 -15px;
  background-color: rgba(0, 0, 0, 0.1);
}

.c-block-menu-menu-links-servicos .menu {
  overflow-x: auto;
  display: flex;
  align-items: center;
  max-width: 100vw;
  padding: var(--sp-six) 0;
  margin: 0 -15px;
}

.c-block-menu-menu-links-servicos .container {
  display: flex;
  flex-flow: column;
}

@media screen and (min-width: 481px) {
  .c-block-menu-menu-links-servicos .menu {
    justify-content: center;
  }
}

@media screen and (min-width: 993px) {
  .c-block-menu-menu-links-servicos li {
    width: 25%;
    padding: 0 var(--sp-unit);
  }
  .c-block-menu-menu-links-servicos__link {
    width: initial;
  }
  .c-block-menu-menu-links-servicos__header {
    width: 33%;
    margin: initial;
  }
  .c-block-menu-menu-links-servicos .menu {
    width: 67%;
    padding: var(--sp-six) var(--sp-twelve);
  }
  .c-block-menu-menu-links-servicos .container {
    flex-flow: initial;
  }
}

@media screen and (min-width: 1201px) {
  .c-block-menu-menu-links-servicos li {
    font-size: var(--fz-xm);
  }
}

/*------------------------------------*\
  Components.............Card highlighted
\*------------------------------------*/
.view-highlighted {
  color: var(--cl-white);
  padding-left: var(--sp-unit);
  padding-right: var(--sp-unit);
  background-color: var(--cl-primary);
}

.view-highlighted p {
  font-size: var(--fz-s);
  margin: 0 0 var(--sp-unit);
}

.view-highlighted .title {
  font-size: var(--fz-l);
  font-weight: 600;
}

.view-highlighted .views-row:not(:first-child) {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.view-highlighted .views-row {
  padding: var(--sp-unit-half) 0;
}

@media screen and (min-width: 993px) {
  .view-highlighted {
    width: 45%;
    padding-left: var(--sp-unit-half);
    padding-right: 0;
  }
}

.c-block-views-highlighted-block_1 {
  position: relative;
  width: 100%;
  margin-bottom: var(--sp-eighty);
}

.c-block-views-highlighted-block_1 .container {
  position: relative;
  z-index: 5;
  display: flex;
  flex-flow: column;
}

.c-block-views-highlighted-block_1__cover {
  display: flex;
  align-items: flex-end;
  padding-top: var(--sp-unit-half);
  padding-left: var(--sp-unit-half);
  background-color: var(--cl-gray-200);
  border-top-left-radius: var(--sp-twelve);
  position: relative;
}

.c-block-views-highlighted-block_1__cover img {
  height: auto;
  position: relative;
  z-index: 5;
  bottom: -4px;
}

@media screen and (min-width: 993px) {
  .c-block-views-highlighted-block_1::after, .c-block-views-highlighted-block_1__cover::after {
    content: '';
    display: block;
    width: 50%;
    height: 100%;
    background-color: var(--cl-primary);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
  }
  .c-block-views-highlighted-block_1 .container {
    flex-flow: row;
    justify-content: space-between;
  }
  .c-block-views-highlighted-block_1__cover {
    width: 55%;
  }
}

/*------------------------------------*\
  Components.............Courses highlight
\*------------------------------------*/
@media screen and (min-width: 480px) and (max-width: 991px) {
  .c-cursos-destaque .views-row {
    float: left;
    clear: none;
    text-align: inherit;
    width: 49%;
    margin-left: 0%;
    margin-right: 2%;
  }
  .c-cursos-destaque .views-row::after {
    display: block;
    clear: both;
    content: "";
  }
  .c-cursos-destaque .views-row:nth-child(n) {
    margin-right: 2%;
    float: left;
    clear: none;
  }
  .c-cursos-destaque .views-row:nth-child(2n) {
    margin-right: 0%;
    float: right;
  }
  .c-cursos-destaque .views-row:nth-child(2n + 1) {
    clear: both;
  }
}

@media screen and (min-width: 993px) {
  .c-cursos-destaque .views-row {
    float: left;
    clear: none;
    text-align: inherit;
    width: 24.25%;
    margin-left: 0%;
    margin-right: 1%;
    margin-bottom: 0;
  }
  .c-cursos-destaque .views-row::after {
    display: block;
    clear: both;
    content: "";
  }
  .c-cursos-destaque .views-row:last-child {
    margin-right: 0%;
  }
}

@media screen and (min-width: 768px) {
  .c-cursos-destaque-three .views-row {
    float: left;
    clear: none;
    text-align: inherit;
    width: 32.66667%;
    margin-left: 0%;
    margin-right: 1%;
  }
  .c-cursos-destaque-three .views-row::after {
    display: block;
    clear: both;
    content: "";
  }
  .c-cursos-destaque-three .views-row:last-child {
    margin-right: 0%;
  }
}

.c-block-views-produto-block_1 {
  position: relative;
  margin-bottom: var(--sp-triple);
}

.c-block-views-produto-block_1__title {
  margin-bottom: var(--sp-unit);
  padding-right: var(--sp-triple);
}

@media screen and (min-width: 768px) {
  .c-block-views-produto-block_1__title {
    padding-right: 0;
  }
}

.quicktabs_tabpage .c-card-cursos .c-card-cursos .views-row {
  font-size: var(--fz-s);
  width: 100%;
  border: 0;
  box-shadow: none;
}

.quicktabs_tabpage .c-card-cursos .c-card-cursos .views-field-name {
  font-weight: 600;
}

@media screen and (min-width: 993px) {
  .quicktabs_tabpage .field-content > .c-card-cursos {
    display: flex;
  }
  .quicktabs_tabpage .field-content > .c-card-cursos > .attachment {
    width: 23.5%;
  }
  .quicktabs_tabpage .field-content > .c-card-cursos > .view-content {
    width: 75%;
    margin-left: auto;
  }
}

.c-block-views-Categorias_Carrocel-block_2 .quicktabs_tabs li.active,
.c-block-views-Categorias_Carrocel-block_2 .quicktabs_main {
  position: relative;
}

.c-block-views-Categorias_Carrocel-block_2 .qt_tab {
  display: inline-block;
  color: var(--cl-primary);
  font-weight: 600;
  padding: var(--sp-quarter) 0 0;
  border-bottom: 2px solid transparent;
}

.c-block-views-Categorias_Carrocel-block_2 .qt_tab:hover {
  text-decoration: none;
  border-bottom-color: currentColor;
}

.c-block-views-Categorias_Carrocel-block_2 .quicktabs_tabs li.active .qt_tab {
  color: var(--cl-blue);
  border-bottom-color: currentColor;
}

.c-block-views-Categorias_Carrocel-block_2 .quicktabs_tabs,
.c-block-views-Categorias_Carrocel-block_2 .quicktabs_tabs li {
  padding: 0;
}

.c-block-views-Categorias_Carrocel-block_2 .quicktabs_tabs {
  margin-bottom: var(--sp-unit);
}

.c-block-views-Categorias_Carrocel-block_2 .views-slideshow-controls-top {
  top: -52px;
}

@media screen and (max-width: 767px) {
  .c-block-views-Categorias_Carrocel-block_2 .quicktabs_tabs li {
    display: block;
  }
  .c-block-views-Categorias_Carrocel-block_2 .qt_tab {
    padding: var(--sp-half) var(--sp-twelve);
    margin-bottom: var(--sp-half);
  }
}

@media screen and (min-width: 768px) {
  .c-block-views-Categorias_Carrocel-block_2 .quicktabs_tabs li.active::before {
    content: '';
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-top-color: var(--cl-blue);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -14px;
  }
  .c-block-views-Categorias_Carrocel-block_2 .quicktabs_tabs li:not(:first-child) {
    margin-left: var(--sp-unit);
  }
}

@media screen and (min-width: 993px) {
  .c-block-views-Categorias_Carrocel-block_2 .quicktabs_tabs {
    width: 75%;
    margin-left: auto;
  }
}

@media screen and (min-width: 1201px) {
  .c-block-views-Categorias_Carrocel-block_2__title {
    margin-bottom: -30px;
  }
}

.c-block-views-Categorias_Carrocel-block_2 .views_slideshow_singleframe_teaser_section {
  width: 100%;
}

.c-block-views-Categorias_Carrocel-block_2 .views_slideshow_singleframe_teaser_section .views_slideshow_singleframe_slide {
  position: relative !important;
}

@media screen and (min-width: 768px) {
  .c-block-views-Categorias_Carrocel-block_2 .views_slideshow_singleframe_teaser_section .views_slideshow_singleframe_slide {
    height: 258px;
  }
}

/*------------------------------------*\
  Components.............Teacher home
\*------------------------------------*/
.c-professores-destaque .views-field-field-perf-foto-fid a {
  width: 80px;
  height: 80px;
}

.c-professores-destaque .views-field-field-perf-titulacao-value {
  color: var(--cl-white);
}

.c-professores-destaque .more-link {
  text-align: center;
}

.c-professores-destaque .more-link a {
  display: block;
  color: var(--cl-white);
  text-transform: uppercase;
  font-size: var(--fz-s);
  font-weight: 700;
  max-width: 300px;
  padding: var(--sp-twelve) var(--sp-double);
  margin: 0 auto;
  background: linear-gradient(90deg, #0f82e6 0%, #0e6ec1 47.36%);
  border-radius: 50rem;
}

.c-professores-destaque .more-link a:hover {
  text-decoration: none;
}

.c-professores-destaque div.views-row {
  width: 24%;
  margin-bottom: 0;
}

.c-professores-destaque div.view-content {
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: var(--sp-double);
}

@media screen and (max-width: 991px) {
  .c-professores-destaque div.views-row {
    width: auto;
    grid-template-columns: 80px 200px;
    margin: 0 var(--sp-half);
  }
  .c-professores-destaque div.view-content {
    width: 100vw;
    overflow-x: auto;
    padding: 8px 0 15px;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.c-block-views-Professores-block_1 {
  position: relative;
  padding: var(--sp-triple) 0;
  margin-bottom: var(--sp-eighty);
  background: linear-gradient(90deg, #5c8d4a 0%, #8ec43b 100%);
}

.c-block-views-Professores-block_1::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: url("../images/teacher-bg.png") center no-repeat;
  position: absolute;
  top: 0;
}

.c-block-views-Professores-block_1__title {
  color: var(--cl-white);
  text-align: center;
  font-weight: 600;
  font-size: var(--fz-l);
  margin-bottom: var(--sp-double);
}

.c-block-views-Professores-block_1__desc {
  display: block;
  color: var(--cl-white);
  font-style: normal;
  text-align: center;
  font-weight: 600;
  margin-top: var(--sp-twelve);
}

.c-block-views-Professores-block_1 .container {
  position: relative;
  z-index: 5;
}

@media screen and (min-width: 993px) {
  .c-block-views-Professores-block_1__title {
    margin-bottom: var(--sp-triple);
  }
}

/*------------------------------------*\
  Components.............Testimonials home
\*------------------------------------*/
.c-block-views-Depoimentos-block_1 {
  margin-bottom: var(--sp-eighty);
}

.c-block-views-Depoimentos-block_1__btns {
  margin-top: auto;
}

.c-block-views-Depoimentos-block_1__column {
  display: flex;
  flex-flow: column;
  margin-bottom: var(--sp-unit);
}

@media screen and (min-width: 993px) {
  .c-block-views-Depoimentos-block_1__column {
    width: 33%;
    margin-bottom: 0;
  }
  .c-block-views-Depoimentos-block_1__body {
    width: 67%;
  }
  .c-block-views-Depoimentos-block_1 .container {
    display: flex;
  }
}

.c-depoimentos-home div.view-content {
  align-items: flex-start;
  flex-wrap: nowrap;
}

.c-depoimentos-home div.views-row {
  margin-bottom: 0;
}

.c-depoimentos-home div.views-row:first-child {
  margin-top: var(--sp-double);
}

.c-depoimentos-home div.views-row:last-child {
  margin-top: var(--sp-triple);
}

@media screen and (max-width: 991px) {
  .c-depoimentos-home div.view-content {
    overflow-x: auto;
    padding: var(--sp-unit) 0;
    margin: 0 -15px;
  }
}

@media screen and (min-width: 768px) {
  .c-depoimentos-home div.views-row {
    min-width: initial;
  }
}

/*------------------------------------*\
  Components.............Card certificates
\*------------------------------------*/
.c-block-menu-menu-certificados {
  padding: 15px;
  background: rgba(0, 0, 0, 0.1) url("../images/certificate-bg.png") center no-repeat;
  background-size: cover;
}

.c-block-menu-menu-certificados li.leaf {
  list-style: none;
  padding: 0;
  margin: 0 0 var(--sp-half);
}

.c-block-menu-menu-certificados .menu {
  padding-left: 0;
  list-style: none;
}

.c-block-menu-menu-certificados__title {
  margin-bottom: var(--sp-unit);
}

.c-block-menu-menu-certificados__body::before {
  content: 'Certificado do seu curso, consulte a autenticação válida em todo Brasil.';
  display: block;
  font-size: var(--fz-s);
  font-weight: 600;
  margin-bottom: var(--sp-twelve);
}

@media screen and (min-width: 768px) {
  .c-block-menu-menu-certificados {
    width: 53%;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}

@media screen and (min-width: 993px) {
  .c-block-menu-menu-certificados {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    width: 62.6%;
    padding: var(--sp-triple) 15px;
  }
  .c-block-menu-menu-certificados__title, .c-block-menu-menu-certificados__body {
    width: 100%;
  }
  .c-block-menu-menu-certificados__body {
    display: flex;
  }
  .c-block-menu-menu-certificados__body::before {
    width: 33%;
    margin-right: var(--sp-double);
  }
}

@media screen and (min-width: 1441px) {
  .c-block-menu-menu-certificados__title, .c-block-menu-menu-certificados__body {
    width: 885px;
  }
}

/*------------------------------------*\
  Components.............Card faq
\*------------------------------------*/
.c-block-block-11 {
  padding: 2.5%;
  border: 1px solid rgba(9, 77, 136, 0.4);
  margin: 15px;
  background: url("../images/faq-bg.png") center no-repeat;
  background-size: cover;
  border-radius: 2px;
}

.c-block-block-11 h5 {
  font-weight: 800;
  margin-bottom: var(--sp-unit);
}

.c-block-block-11 p {
  font-size: var(--fz-s);
  font-weight: 600;
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .c-block-block-11 {
    max-width: 320px;
    margin: 0 15px 0 auto;
  }
}

@media screen and (min-width: 993px) {
  .c-block-block-11 {
    width: 33%;
    max-width: initial;
    margin: 0 auto 0 var(--sp-double);
  }
}

@media screen and (min-width: 1441px) {
  .c-block-block-11 {
    width: 355px;
  }
}

.c-banner-highlighted img,
.c-block-block-19 img {
  display: block;
  width: 100%;
  max-width: 728px;
  margin: 0 auto;
}

.o-region-content_bottom {
  margin-bottom: var(--sp-eighty);
}

@media screen and (min-width: 768px) {
  .front .o-region-content_bottom {
    display: flex;
    flex-wrap: wrap;
  }
  .front .c-block-views-Depoimentos-block_1 {
    width: 100%;
  }
}

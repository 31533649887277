
/*------------------------------------*\
  Settings...............Set tools and configurations for the contents
\*------------------------------------*/
@import "../node_modules/include-media/dist/_include-media";
@import '../node_modules/jeet/scss/index';
@import 'settings/mixins';
@import 'settings/variables';
@import 'settings/helper-gradient-angle';
@import 'settings/linear-gradient';
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
@import "settings/root"; // **
/*------------------------------------*\
  Layouts................Macro arrangement of a web page, including any grid systems
\*------------------------------------*/
@import 'layouts/home';

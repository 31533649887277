/*------------------------------------*\
  Components.............Card highlighted
\*------------------------------------*/

.view-highlighted {
  color: var(--cl-white);
  padding-left: var(--sp-unit);
  padding-right: var(--sp-unit);
  background-color: var(--cl-primary);

  p {
    font-size: var(--fz-s);
    margin: 0 0 var(--sp-unit);
  }

  .title {
    font-size: var(--fz-l);
    font-weight: 600;
  }

  .views-row:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, .15);
  }
  .views-row {
    padding: var(--sp-unit-half) 0;
  }

  @include media('screen', '>medium') {
    width: 45%;
    padding-left: var(--sp-unit-half);
    padding-right: 0;
  }
}

.c-block-views-highlighted-block_1 {
  position: relative;
  width: 100%;
  margin-bottom: var(--sp-eighty);

  .container {
    position: relative;
    z-index: 5;
    display: flex;
    flex-flow: column;
  }

  &__cover {
    display: flex;
    align-items: flex-end;
    padding-top: var(--sp-unit-half);
    padding-left: var(--sp-unit-half);
    background-color: var(--cl-gray-200);
    border-top-left-radius: var(--sp-twelve);
    position: relative;
    
    img {
      height: auto;
      position: relative;
      z-index: 5;
      bottom: -4px;
    }
  }

  @include media('screen', '>medium') {
    &::after, 
    &__cover::after {
      content: '';
      display: block;
      @include square(50%, 100%);
      background-color: var(--cl-primary);
      @include centerer(right);
    }
    
    .container {
      flex-flow: row;
      justify-content: space-between;
    }

    &__cover {
      width: 55%;
    }
  }
}
/*------------------------------------*\
  Layouts................Home
\*------------------------------------*/

// 
// blocks
// 
@import 'components/banner';
@import 'components/links-servicos';
@import 'components/card-highlighted';
@import 'components/courses-highlight';
@import 'components/sect-teachers-home';
@import 'components/testimonials-home';
@import 'components/card-certificates';
@import 'components/card-faq';

.c-banner-highlighted img, 
.c-block-block-19 img {
  display: block;
  width: 100%;
  max-width: 728px;
  margin: 0 auto;
}

// 
// regions
// 

.o-region-content_bottom {
  margin-bottom: var(--sp-eighty);
}


.front {
  
  @include media('screen', '>=small') {
    .o-region-content_bottom {
      display: flex;
      flex-wrap: wrap;
    }
    
    .c-block-views-Depoimentos-block_1 { width: 100%; }
  }
}
